import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PrivacyPolicy from "../layouts/PrivacyPolicy";

const PrivacyPolicyFr = () => {
  return (
    <Layout 
    lang="fr-CA" 
    switchLink='/en/privacy-policy' 
    slider={false} 
    title="Politique de confidentialité" >
      <Seo 
        title="Parlez-nous de votre projet d'affichage numérique dynamique"
        description="Laissez-nous vous montrer comment les systèmes d'Attractif  facilitent la communication avec votre clientèle et vos employés."
       />
      <PrivacyPolicy lang="fr-CA" />
    </Layout>
  )
}

export default PrivacyPolicyFr
